<template>
  <div class="nav_bar">
    <nav id="navbarMenu" class="navbar navbar-expand-lg navbar-dark bg-dark min-width-home">
      <div class="container-fluid">
        <a class="navbar-brand d-flex align-items-center" href="#">Ashleys Party</a>
        <button class="navbar-toggler collapsed" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-bars"></i>
        </button>
        <div class="navbar-collapse collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/home">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/order">Order</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/events">Events</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/account">Account</router-link>
            </li>
            <li class="nav-item">
              <a v-on:click="toggleDarkMode" class="nav-link">{{darkModeText}}</a>
            </li>

            <li class="nav-item">
              <a v-on:click="signOut" class="nav-link">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from 'cash-dom'
import Darkmode from 'drkmd-js'
import { getDarkMode } from '../assets/js/darkMode'

export default {
  data() {
    return {
      darkModeText: ''
    }
  },
  methods: {
    toggleDarkMode() {
      const darkmode = new Darkmode()
      darkmode.toggle()

      this.darkText()
    },
    darkText() {
      const darkTF = getDarkMode()

      if (darkTF) {
        this.darkModeText = 'Light Mode'
      } else {
        this.darkModeText = 'Dark Mode'
      }
    },
    signOut() {
      this.$store.dispatch('signOut');
    }
  },
  mounted() {
    this.darkText()
  },
  watch: {
    $route(to, from) {
      if ($('#navbarNav').hasClass('show')) {
        const myCollapse = document.getElementById('navbarNav')
        const bsCollapse = new this.$mdb.Collapse(myCollapse)
        bsCollapse.hide()
      }
    }
  }
}
</script>
